export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Applications',
    route: 'apps',
    icon: 'CodepenIcon',
  },
  {
    header: 'Getting Started',
  },
  {
    title: 'API',
    route: 'api',
    icon: 'BookOpenIcon',
  },
  {
    title: 'SDKs',
    route: 'sdk',
    icon: 'PackageIcon',
  },
]
